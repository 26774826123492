<template>
  <div
    class="app-toolbar"
    :class="{
      'fill-background': !isTransparent,
      scrolled: isScrolled,
      subpage: isSubpage,
    }"
  >
    <v-row
      no-gutters
      :style="{ height: `${gmToolbarHeight}px` }"
      class="align-center"
    >
      <v-col cols="3">
        <a
          v-if="showBackButton"
          :style="{ color: gmSecondaryColor }"
          @click.stop="$router.go(-1)"
        >
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            style="font-size: 25px"
          />
        </a>
      </v-col>
      <v-col cols="6">
        <div class="text-overflow-ellipsis text-center">
          <template v-if="showGroupName">
            <span
              v-if="isScrolled || isSubpage"
              :style="{ color: gmPrimaryColor }"
              class="font-weight-medium cursor-pointer event-name"
              @click="routeToHome"
            >
              {{ groupName }}
            </span>
          </template>
        </div>
      </v-col>
      <v-col cols="3">
        <v-row class="align-center no-gutters flex-nowrap justify-end">
          <AppLanguageSwitcher v-if="showLanguages" />
          <AppLogin />
          <transition name="fade" mode="out-in">
            <a
              v-if="showNavigationDrawer"
              @click.stop="navigationDrawer = !navigationDrawer"
              class="sw-ml-5 navigation-toggler"
            >
              <v-icon
                style="font-size: 35px"
                :style="{ color: gmSecondaryColor }"
                >mdi-menu
              </v-icon>
              <span
                :style="{
                  color: gmSecondaryColor,
                  display: 'block',
                }"
                class="toggler-caption text-center"
                >{{ $vuetify.lang.t("$vuetify.menu") }}</span
              >
            </a>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppLanguageSwitcher from "@/components/AppLanguageSwitcher.vue";
import heightCalculator from "@/utils/lib/height-calculator";
import AppLogin from "@/components/AppLogin.vue";

export default {
  data: () => ({
    routesWithBackButton: ["ArticlePage", "MessagePage"],
  }),
  computed: {
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupName() {
      return this.$store?.getters?.group?.name;
    },
    showGroupName() {
      return (
        this.$store?.getters?.groupAttributes?.lp_show_group_name_in_header ||
        false
      );
    },
    supportedLanguages() {
      return (
        this.$store?.getters?.groupAttributes?.supported_languages?.split(
          ",",
        ) || []
      );
    },
    showLanguages() {
      return this.supportedLanguages.length > 1;
    },
    destinations() {
      return this.$store?.getters?.navigation?.destinations || [];
    },
    showNavigationDrawer() {
      return !!this.destinations.filter((el) => el.component !== "FrontPage")
        .length;
    },
    navigationDrawer: {
      get() {
        return this.$store?.getters?.navigationDrawer;
      },
      set(val) {
        this.$store.commit("SET_NAVIGATION_DRAWER", val);
      },
    },
    showBackButton() {
      return this.routesWithBackButton?.includes(this.$route?.name);
    },
    isTransparent() {
      return (
        this.$store?.getters?.group?.attributes?.lp_transparent_toolbar || false
      );
    },
    windowHeight() {
      return this.$store?.getters?.windowHeight;
    },
    windowWidth() {
      return this.$store?.getters?.windowWidth;
    },
    getInnerHeight() {
      return this.gmToolbarHeight;
    },
    isScrolled() {
      const imageHeight = this.$store?.getters?.group?.attributes
        ?.lp_header_background_image?.height;
      const imageWidth = this.$store?.getters?.group?.attributes
        ?.lp_header_background_image?.width;
      const height = (imageHeight / imageWidth) * this.windowWidth;

      const scrollY = Math.abs(this.$store?.getters?.scrollY);

      const headerImageMinWidth =
        (height ||
          heightCalculator({
            windowHeight: this.windowHeight,
            windowWidth: this.windowWidth,
          })) - (this.isTransparent ? this.getInnerHeight : 0);

      return scrollY >= headerImageMinWidth;
    },
    isSubpage() {
      return this.$route?.name !== "FrontPage";
    },
  },
  components: {
    AppLanguageSwitcher,
    AppLogin,
  },
  methods: {
    routeToHome() {
      this.$router.push({ name: "FrontPage" }).catch(() => {
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.app-toolbar {
  width: 100%;
  padding: 0 90px;
  position: fixed;
  z-index: 1;

  &.fill-background,
  &.scrolled,
  &.subpage {
    transition: all 0.2s;
    background-color: #fff;
  }

  /* VERY SPECIFIC STYLING FOR THIS APP */
  /* SHOULD BE RE-EVALUATED LATER */
  .event-name {
    font-size: 20px;
  }

  .navigation-toggler {
    position: relative;

    .toggler-caption {
      position: absolute;
      right: -79px;
      width: 200px;
      font-weight: 300;
      font-size: 20px;
    }
  }
}

@media (max-width: 900px) {
  .app-toolbar {
    padding: 0 32px;

    /* VERY SPECIFIC STYLING FOR THIS APP */
    /* SHOULD BE RE-EVALUATED LATER */
    .event-name {
      font-size: 15px;
    }

    .navigation-toggler {
      margin-left: 15px !important;
    }
  }
}
</style>
