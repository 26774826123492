<template>
  <div class="navigation-drawer">
    <v-navigation-drawer
      v-model="navigationDrawer"
      fixed
      temporary
      width="100%"
    >
      <div class="toolbar">
        <v-row
          :style="{ height: `${gmToolbarHeight}px` }"
          class="no-gutters align-center"
        >
          <v-col cols="6">
            <div class="text-overflow-ellipsis">
              <template v-if="showGroupName">
                <span
                  :style="{ color: gmPrimaryColor }"
                  class="font-weight-medium cursor-pointer event-name"
                  @click="routeToHome"
                >
                  {{ groupName }}
                </span>
              </template>
            </div>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center no-gutters flex-nowrap justify-end">
              <a @click.stop="navigationDrawer = !navigationDrawer">
                <v-icon
                  style="font-size: 35px"
                  :style="{ color: gmSecondaryColor }"
                  >mdi-close</v-icon
                >
              </a>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-card flat class="d-flex fill-height align-center rounded-0">
        <v-row no-gutters class="pr-1 justify-center">
          <v-col cols="12" sm="6" :style="cssVariables">
            <overlay-scrollbars
              :options="scrollbarOptions"
              :style="{ maxHeight: '70vh' }"
            >
              <div class="text-center">
                <div :style="{ display: 'inline-block' }">
                  <div
                    v-for="(item, i) in navigation.destinations"
                    :key="`item-${i}`"
                    class="text-left text-overflow-ellipsis menu-item"
                  >
                    <a
                      :style="{ color: gmSecondaryColor }"
                      class="menu-item-name"
                      @click.stop="routeTo(item)"
                      >{{ item.name }}</a
                    >
                  </div>
                </div>
              </div>
            </overlay-scrollbars>
          </v-col>
        </v-row>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    scrollbarOptions: {
      autoUpdate: true,
      sizeAutoCapable: true,
      scrollbars: {
        visibility: "auto",
        autoHide: "never",
        autoHideDelay: 1300,
      },
      overflowBehavior: {
        x: "scroll",
      },
    },
  }),
  computed: {
    navigation() {
      return this.$store?.getters?.navigation;
    },
    groupName() {
      return this.$store?.getters?.group?.name;
    },
    showGroupName() {
      return (
        this.$store?.getters?.group?.attributes?.lp_show_group_name_in_header ||
        false
      );
    },
    navigationDrawer: {
      get() {
        return this.$store?.getters?.navigationDrawer;
      },
      set(val) {
        this.$store.commit("SET_NAVIGATION_DRAWER", val);
      },
    },
    isScrolled() {
      return this.$store?.getters?.scrollY < 0 ? true : false;
    },
  },
  watch: {
    navigationDrawer: {
      immediate: true,
      handler(val) {
        const root = document.getElementsByTagName("html")[0];

        if (!val) {
          root.classList.remove("overflow-hidden");
          return;
        }

        root.classList.add("overflow-hidden");
      },
    },
  },
  methods: {
    routeTo(destination) {
      if (destination.component === "ArticlePage" && destination.params) {
        destination.params = JSON.parse(destination.params);

        if (destination?.params?.article_id) destination.component = "Page";
      }

      this.$router
        .push({
          name: destination.component,
          params: {
            group_plugin_id: destination.group_plugin_id,
            ...destination.params,
          },
        })
        .catch((error) => {
          if (error) {
            this.navigationDrawer = false;
          }
        });
    },
    routeToHome() {
      this.$router.push({ name: "FrontPage" }).catch(() => {
        this.navigationDrawer = false;
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.navigation-drawer {
  .toolbar {
    width: 100%;
    padding: 0 90px;
    position: fixed;
    z-index: 1;

    .event-name {
      font-size: 20px;
    }

    .svg-inline--fa {
      vertical-align: -5px;
    }
  }

  .menu-item {
    padding-top: 10px;
    padding-bottom: 9px;

    .menu-item-name {
      font-size: 35px !important;
      font-weight: 300 !important;
    }
  }
}

@media (max-width: 900px) {
  .navigation-drawer {
    .menu-item {
      padding: 10px 0;

      .menu-item-name {
        font-size: 22px !important;
      }
    }

    .toolbar {
      padding: 0 30px;

      /* VERY SPECIFIC STYLING FOR THIS APP */
      /* SHOULD BE RE-EVALUATED LATER */
      .event-name {
        font-size: 15px;
      }
    }

    .toolbar > .row {
      height: 90px !important;
    }
  }
}
</style>
