import en from "@/locale/en.ts";
import da from "@/locale/da.ts";

const customErrorMessage = {
  en: {
    fields: {
      email: {
        emailUser: en.emailUser,
      },
    },
  },
  da: {
    fields: {
      email: {
        emailUser: da.emailUser,
      },
    },
  },
};

export default customErrorMessage;
