<template>
  <div class="footer sw-mt-8 sw-mb-4">
    <v-row no-gutters class="sw-mb-3">
      <v-col v-if="footerText" cols="12" class="sw-mb-1 text-center">
        <v-card flat max-width="520" class="sw-mx-auto">
          <div v-html="footerText"></div>
        </v-card>
      </v-col>
      <v-col v-if="footerLogo" cols="12" class="text-center">
        <img :src="footerLogo" class="logo" alt="logo" />
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-center align-baseline">
      <a :href="poweredByUrl" target="_blank" class="sw-small pr-1">
        {{ $vuetify.lang.t("$vuetify.createdBy") }}
      </a>
      <a :href="poweredByUrl" target="_blank">
        <img
          :src="require('@/assets/images/logo.png')"
          alt="logo"
          class="powered-by-logo"
        />
      </a>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    footerText() {
      return this.$store?.getters?.groupAttributes?.lp_footer_text;
    },
    footerLogo() {
      return this.$store?.getters?.groupAttributes?.lp_footer_logo?.url;
    },
    poweredByUrl() {
      return process.env.VUE_APP_POWERED_BY_URL;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  .logo {
    max-height: 120px;
  }

  .powered-by-logo {
    max-width: 42px;
  }

  a {
    text-decoration: none;
  }
}

@media (max-width: 900px) {
  .footer {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>
