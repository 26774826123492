export const countries = [
  {
    dialCode: 93,
    iso2: "af",
    name: "Afghanistan (‫افغانستان‬‎)",
    default: false,
  },
  { dialCode: 355, iso2: "al", name: "Albania (Shqipëri)", default: false },
  { dialCode: 213, iso2: "dz", name: "Algeria (‫الجزائر‬‎)", default: false },
  // { dialCode: 1, iso2: "as", name: "American Samoa", default: false },
  { dialCode: 376, iso2: "ad", name: "Andorra", default: false },
  { dialCode: 244, iso2: "ao", name: "Angola", default: false },
  // { dialCode: 1, iso2: "ai", name: "Anguilla", default: false },
  // { dialCode: 1, iso2: "ag", name: "Antigua and Barbuda", default: false },
  { dialCode: 54, iso2: "ar", name: "Argentina", default: false },
  { dialCode: 374, iso2: "am", name: "Armenia (Հայաստան)", default: false },
  { dialCode: 297, iso2: "aw", name: "Aruba", default: false },
  { dialCode: 61, iso2: "au", name: "Australia", default: false },
  { dialCode: 43, iso2: "at", name: "Austria (Österreich)", default: false },
  {
    dialCode: 994,
    iso2: "az",
    name: "Azerbaijan (Azərbaycan)",
    default: false,
  },
  // { dialCode: 1, iso2: "bs", name: "Bahamas", default: false },
  { dialCode: 973, iso2: "bh", name: "Bahrain (‫البحرين‬‎)", default: false },
  { dialCode: 880, iso2: "bd", name: "Bangladesh (বাংলাদেশ)", default: false },
  // { dialCode: 1, iso2: "bb", name: "Barbados", default: false },
  { dialCode: 375, iso2: "by", name: "Belarus (Беларусь)", default: false },
  { dialCode: 32, iso2: "be", name: "Belgium (België)", default: false },
  { dialCode: 501, iso2: "bz", name: "Belize", default: false },
  { dialCode: 229, iso2: "bj", name: "Benin (Bénin)", default: false },
  // { dialCode: 1, iso2: "bm", name: "Bermuda", default: false },
  { dialCode: 975, iso2: "bt", name: "Bhutan (འབྲུག)", default: false },
  { dialCode: 591, iso2: "bo", name: "Bolivia", default: false },
  {
    dialCode: 387,
    iso2: "ba",
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    default: false,
  },
  { dialCode: 267, iso2: "bw", name: "Botswana", default: false },
  { dialCode: 55, iso2: "br", name: "Brazil (Brasil)", default: false },
  {
    dialCode: 246,
    iso2: "io",
    name: "British Indian Ocean Territory",
    default: false,
  },
  // { dialCode: 1, iso2: "vg", name: "British Virgin Islands", default: false },
  { dialCode: 673, iso2: "bn", name: "Brunei", default: false },
  { dialCode: 359, iso2: "bg", name: "Bulgaria (България)", default: false },
  { dialCode: 226, iso2: "bf", name: "Burkina Faso", default: false },
  { dialCode: 257, iso2: "bi", name: "Burundi (Uburundi)", default: false },
  { dialCode: 855, iso2: "kh", name: "Cambodia (កម្ពុជា)", default: false },
  { dialCode: 237, iso2: "cm", name: "Cameroon (Cameroun)", default: false },
  // { dialCode: 1, iso2: "ca", name: "Canada", default: false },
  {
    dialCode: 238,
    iso2: "cv",
    name: "Cape Verde (Kabu Verdi)",
    default: false,
  },
  { dialCode: 599, iso2: "bq", name: "Caribbean Netherlands", default: false },
  // { dialCode: 1, iso2: "ky", name: "Cayman Islands", default: false },
  {
    dialCode: 236,
    iso2: "cf",
    name: "Central African Republic (République centrafricaine)",
    default: false,
  },
  { dialCode: 235, iso2: "td", name: "Chad (Tchad)", default: false },
  { dialCode: 56, iso2: "cl", name: "Chile", default: false },
  { dialCode: 86, iso2: "cn", name: "China (中国)", default: false },
  { dialCode: 61, iso2: "cx", name: "Christmas Island", default: false },
  { dialCode: 61, iso2: "cc", name: "Cocos (Keeling) Islands", default: false },
  { dialCode: 57, iso2: "co", name: "Colombia", default: false },
  { dialCode: 269, iso2: "km", name: "Comoros (‫جزر القمر‬‎)", default: false },
  {
    dialCode: 243,
    iso2: "cd",
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    default: false,
  },
  {
    dialCode: 242,
    iso2: "cg",
    name: "Congo (Republic) (Congo-Brazzaville)",
    default: false,
  },
  { dialCode: 682, iso2: "ck", name: "Cook Islands", default: false },
  { dialCode: 506, iso2: "cr", name: "Costa Rica", default: false },
  { dialCode: 225, iso2: "ci", name: "Côte d’Ivoire", default: false },
  { dialCode: 385, iso2: "hr", name: "Croatia (Hrvatska)", default: false },
  { dialCode: 53, iso2: "cu", name: "Cuba", default: false },
  { dialCode: 599, iso2: "cw", name: "Curaçao", default: false },
  { dialCode: 357, iso2: "cy", name: "Cyprus (Κύπρος)", default: false },
  {
    dialCode: 420,
    iso2: "cz",
    name: "Czech Republic (Česká republika)",
    default: false,
  },
  { dialCode: 45, iso2: "dk", name: "Denmark (Danmark)", default: true },
  { dialCode: 253, iso2: "dj", name: "Djibouti", default: false },
  // { dialCode: 1, iso2: "dm", name: "Dominica", default: false },
  // {
  //   dialCode: 1,
  //   iso2: "do",
  //   name: "Dominican Republic (República Dominicana)",
  //   default: false,
  // },
  { dialCode: 593, iso2: "ec", name: "Ecuador", default: false },
  { dialCode: 20, iso2: "eg", name: "Egypt (‫مصر‬‎)", default: false },
  { dialCode: 503, iso2: "sv", name: "El Salvador", default: false },
  {
    dialCode: 240,
    iso2: "gq",
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    default: false,
  },
  { dialCode: 291, iso2: "er", name: "Eritrea", default: false },
  { dialCode: 372, iso2: "ee", name: "Estonia (Eesti)", default: false },
  { dialCode: 251, iso2: "et", name: "Ethiopia", default: false },
  {
    dialCode: 500,
    iso2: "fk",
    name: "Falkland Islands (Islas Malvinas)",
    default: false,
  },
  {
    dialCode: 298,
    iso2: "fo",
    name: "Faroe Islands (Føroyar)",
    default: false,
  },
  { dialCode: 679, iso2: "fj", name: "Fiji", default: false },
  { dialCode: 358, iso2: "fi", name: "Finland (Suomi)", default: true },
  { dialCode: 33, iso2: "fr", name: "France", default: false },
  {
    dialCode: 594,
    iso2: "gf",
    name: "French Guiana (Guyane française)",
    default: false,
  },
  {
    dialCode: 689,
    iso2: "pf",
    name: "French Polynesia (Polynésie française)",
    default: false,
  },
  { dialCode: 241, iso2: "ga", name: "Gabon", default: false },
  { dialCode: 220, iso2: "gm", name: "Gambia", default: false },
  { dialCode: 995, iso2: "ge", name: "Georgia (საქართველო)", default: false },
  { dialCode: 49, iso2: "de", name: "Germany (Deutschland)", default: true },
  { dialCode: 233, iso2: "gh", name: "Ghana (Gaana)", default: false },
  { dialCode: 350, iso2: "gi", name: "Gibraltar", default: false },
  { dialCode: 30, iso2: "gr", name: "Greece (Ελλάδα)", default: false },
  {
    dialCode: 299,
    iso2: "gl",
    name: "Greenland (Kalaallit Nunaat)",
    default: false,
  },
  // { dialCode: 1, iso2: "gd", name: "Grenada", default: false },
  { dialCode: 590, iso2: "gp", name: "Guadeloupe", default: false },
  // { dialCode: 1, iso2: "gu", name: "Guam", default: false },
  { dialCode: 502, iso2: "gt", name: "Guatemala", default: false },
  { dialCode: 44, iso2: "gg", name: "Guernsey", default: false },
  { dialCode: 224, iso2: "gn", name: "Guinea (Guinée)", default: false },
  {
    dialCode: 245,
    iso2: "gw",
    name: "Guinea-Bissau (Guiné Bissau)",
    default: false,
  },
  { dialCode: 592, iso2: "gy", name: "Guyana", default: false },
  { dialCode: 509, iso2: "ht", name: "Haiti", default: false },
  { dialCode: 504, iso2: "hn", name: "Honduras", default: false },
  { dialCode: 852, iso2: "hk", name: "Hong Kong (香港)", default: false },
  { dialCode: 36, iso2: "hu", name: "Hungary (Magyarország)", default: false },
  { dialCode: 354, iso2: "is", name: "Iceland (Ísland)", default: false },
  { dialCode: 91, iso2: "in", name: "India (भारत)", default: false },
  { dialCode: 62, iso2: "id", name: "Indonesia", default: false },
  { dialCode: 98, iso2: "ir", name: "Iran (‫ایران‬‎)", default: false },
  { dialCode: 964, iso2: "iq", name: "Iraq (‫العراق‬‎)", default: false },
  { dialCode: 353, iso2: "ie", name: "Ireland", default: false },
  { dialCode: 44, iso2: "im", name: "Isle of Man", default: false },
  { dialCode: 972, iso2: "il", name: "Israel (‫ישראל‬‎)", default: false },
  { dialCode: 39, iso2: "it", name: "Italy (Italia)", default: false },
  // { dialCode: 1, iso2: "jm", name: "Jamaica", default: false },
  { dialCode: 81, iso2: "jp", name: "Japan (日本)", default: false },
  { dialCode: 44, iso2: "je", name: "Jersey", default: false },
  { dialCode: 962, iso2: "jo", name: "Jordan (‫الأردن‬‎)", default: false },
  { dialCode: 7, iso2: "kz", name: "Kazakhstan (Казахстан)", default: false },
  { dialCode: 254, iso2: "ke", name: "Kenya", default: false },
  { dialCode: 686, iso2: "ki", name: "Kiribati", default: false },
  { dialCode: 383, iso2: "xk", name: "Kosovo", default: false },
  { dialCode: 965, iso2: "kw", name: "Kuwait (‫الكويت‬‎)", default: false },
  {
    dialCode: 996,
    iso2: "kg",
    name: "Kyrgyzstan (Кыргызстан)",
    default: false,
  },
  { dialCode: 856, iso2: "la", name: "Laos (ລາວ)", default: false },
  { dialCode: 371, iso2: "lv", name: "Latvia (Latvija)", default: false },
  { dialCode: 961, iso2: "lb", name: "Lebanon (‫لبنان‬‎)", default: false },
  { dialCode: 266, iso2: "ls", name: "Lesotho", default: false },
  { dialCode: 231, iso2: "lr", name: "Liberia", default: false },
  { dialCode: 218, iso2: "ly", name: "Libya (‫ليبيا‬‎)", default: false },
  { dialCode: 423, iso2: "li", name: "Liechtenstein", default: false },
  { dialCode: 370, iso2: "lt", name: "Lithuania (Lietuva)", default: true },
  { dialCode: 352, iso2: "lu", name: "Luxembourg", default: true },
  { dialCode: 853, iso2: "mo", name: "Macau (澳門)", default: false },
  {
    dialCode: 389,
    iso2: "mk",
    name: "Macedonia (FYROM) (Македонија)",
    default: false,
  },
  {
    dialCode: 261,
    iso2: "mg",
    name: "Madagascar (Madagasikara)",
    default: false,
  },
  { dialCode: 265, iso2: "mw", name: "Malawi", default: false },
  { dialCode: 60, iso2: "my", name: "Malaysia", default: false },
  { dialCode: 960, iso2: "mv", name: "Maldives", default: false },
  { dialCode: 223, iso2: "ml", name: "Mali", default: false },
  { dialCode: 356, iso2: "mt", name: "Malta", default: false },
  { dialCode: 692, iso2: "mh", name: "Marshall Islands", default: false },
  { dialCode: 596, iso2: "mq", name: "Martinique", default: false },
  {
    dialCode: 222,
    iso2: "mr",
    name: "Mauritania (‫موريتانيا‬‎)",
    default: false,
  },
  { dialCode: 230, iso2: "mu", name: "Mauritius (Moris)", default: false },
  { dialCode: 262, iso2: "yt", name: "Mayotte", default: false },
  { dialCode: 52, iso2: "mx", name: "Mexico (México)", default: false },
  { dialCode: 691, iso2: "fm", name: "Micronesia", default: false },
  {
    dialCode: 373,
    iso2: "md",
    name: "Moldova (Republica Moldova)",
    default: false,
  },
  { dialCode: 377, iso2: "mc", name: "Monaco", default: false },
  { dialCode: 976, iso2: "mn", name: "Mongolia (Монгол)", default: false },
  { dialCode: 382, iso2: "me", name: "Montenegro (Crna Gora)", default: false },
  // { dialCode: 1, iso2: "ms", name: "Montserrat", default: false },
  { dialCode: 212, iso2: "ma", name: "Morocco (‫المغرب‬‎)", default: false },
  {
    dialCode: 258,
    iso2: "mz",
    name: "Mozambique (Moçambique)",
    default: false,
  },
  {
    dialCode: 95,
    iso2: "mm",
    name: "Myanmar (Burma) (မြန်မာ)",
    default: false,
  },
  { dialCode: 264, iso2: "na", name: "Namibia (Namibië)", default: false },
  { dialCode: 674, iso2: "nr", name: "Nauru", default: false },
  { dialCode: 977, iso2: "np", name: "Nepal (नेपाल)", default: false },
  { dialCode: 31, iso2: "nl", name: "Netherlands (Nederland)", default: false },
  {
    dialCode: 687,
    iso2: "nc",
    name: "New Caledonia (Nouvelle-Calédonie)",
    default: false,
  },
  { dialCode: 64, iso2: "nz", name: "New Zealand", default: false },
  { dialCode: 505, iso2: "ni", name: "Nicaragua", default: false },
  { dialCode: 227, iso2: "ne", name: "Niger (Nijar)", default: false },
  { dialCode: 234, iso2: "ng", name: "Nigeria", default: false },
  { dialCode: 683, iso2: "nu", name: "Niue", default: false },
  { dialCode: 672, iso2: "nf", name: "Norfolk Island", default: false },
  {
    dialCode: 850,
    iso2: "kp",
    name: "North Korea (조선 민주주의 인민 공화국)",
    default: false,
  },
  // { dialCode: 1, iso2: "mp", name: "Northern Mariana Islands", default: false },
  { dialCode: 47, iso2: "no", name: "Norway (Norge)", default: true },
  { dialCode: 968, iso2: "om", name: "Oman (‫عُمان‬‎)", default: false },
  { dialCode: 92, iso2: "pk", name: "Pakistan (‫پاکستان‬‎)", default: false },
  { dialCode: 680, iso2: "pw", name: "Palau", default: false },
  { dialCode: 970, iso2: "ps", name: "Palestine (‫فلسطين‬‎)", default: false },
  { dialCode: 507, iso2: "pa", name: "Panama (Panamá)", default: false },
  { dialCode: 675, iso2: "pg", name: "Papua New Guinea", default: false },
  { dialCode: 595, iso2: "py", name: "Paraguay", default: false },
  { dialCode: 51, iso2: "pe", name: "Peru (Perú)", default: false },
  { dialCode: 63, iso2: "ph", name: "Philippines", default: false },
  { dialCode: 48, iso2: "pl", name: "Poland (Polska)", default: false },
  { dialCode: 351, iso2: "pt", name: "Portugal", default: false },
  // { dialCode: 1, iso2: "pr", name: "Puerto Rico", default: false },
  { dialCode: 974, iso2: "qa", name: "Qatar (‫قطر‬‎)", default: false },
  { dialCode: 262, iso2: "re", name: "Réunion (La Réunion)", default: false },
  { dialCode: 40, iso2: "ro", name: "Romania (România)", default: false },
  { dialCode: 7, iso2: "ru", name: "Russia (Россия)", default: false },
  { dialCode: 250, iso2: "rw", name: "Rwanda", default: false },
  { dialCode: 590, iso2: "bl", name: "Saint Barthélemy", default: false },
  { dialCode: 290, iso2: "sh", name: "Saint Helena", default: false },
  // { dialCode: 1, iso2: "kn", name: "Saint Kitts and Nevis", default: false },
  // { dialCode: 1, iso2: "lc", name: "Saint Lucia", default: false },
  {
    dialCode: 590,
    iso2: "mf",
    name: "Saint Martin (Saint-Martin (partie française))",
    default: false,
  },
  {
    dialCode: 508,
    iso2: "pm",
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    default: false,
  },
  // {
  //   dialCode: 1,
  //   iso2: "vc",
  //   name: "Saint Vincent and the Grenadines",
  //   default: false,
  // },
  { dialCode: 685, iso2: "ws", name: "Samoa", default: false },
  { dialCode: 378, iso2: "sm", name: "San Marino", default: false },
  {
    dialCode: 239,
    iso2: "st",
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    default: false,
  },
  {
    dialCode: 966,
    iso2: "sa",
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    default: false,
  },
  { dialCode: 221, iso2: "sn", name: "Senegal (Sénégal)", default: false },
  { dialCode: 381, iso2: "rs", name: "Serbia (Србија)", default: false },
  { dialCode: 248, iso2: "sc", name: "Seychelles", default: false },
  { dialCode: 232, iso2: "sl", name: "Sierra Leone", default: false },
  { dialCode: 65, iso2: "sg", name: "Singapore", default: false },
  // { dialCode: 1, iso2: "sx", name: "Sint Maarten", default: false },
  { dialCode: 421, iso2: "sk", name: "Slovakia (Slovensko)", default: false },
  { dialCode: 386, iso2: "si", name: "Slovenia (Slovenija)", default: false },
  { dialCode: 677, iso2: "sb", name: "Solomon Islands", default: false },
  { dialCode: 252, iso2: "so", name: "Somalia (Soomaaliya)", default: false },
  { dialCode: 27, iso2: "za", name: "South Africa", default: false },
  { dialCode: 82, iso2: "kr", name: "South Korea (대한민국)", default: false },
  {
    dialCode: 211,
    iso2: "ss",
    name: "South Sudan (‫جنوب السودان‬‎)",
    default: false,
  },
  { dialCode: 34, iso2: "es", name: "Spain (España)", default: false },
  { dialCode: 94, iso2: "lk", name: "Sri Lanka (ශ්‍රී ලංකාව)", default: false },
  { dialCode: 249, iso2: "sd", name: "Sudan (‫السودان‬‎)", default: false },
  { dialCode: 597, iso2: "sr", name: "Suriname", default: false },
  { dialCode: 47, iso2: "sj", name: "Svalbard and Jan Mayen", default: false },
  { dialCode: 268, iso2: "sz", name: "Swaziland", default: false },
  { dialCode: 46, iso2: "se", name: "Sweden (Sverige)", default: true },
  { dialCode: 41, iso2: "ch", name: "Switzerland (Schweiz)", default: false },
  { dialCode: 963, iso2: "sy", name: "Syria (‫سوريا‬‎)", default: false },
  { dialCode: 886, iso2: "tw", name: "Taiwan (台灣)", default: false },
  { dialCode: 992, iso2: "tj", name: "Tajikistan", default: false },
  { dialCode: 255, iso2: "tz", name: "Tanzania", default: false },
  { dialCode: 66, iso2: "th", name: "Thailand (ไทย)", default: false },
  { dialCode: 670, iso2: "tl", name: "Timor-Leste", default: false },
  { dialCode: 228, iso2: "tg", name: "Togo", default: false },
  { dialCode: 690, iso2: "tk", name: "Tokelau", default: false },
  { dialCode: 676, iso2: "to", name: "Tonga", default: false },
  // { dialCode: 1, iso2: "tt", name: "Trinidad and Tobago", default: false },
  { dialCode: 216, iso2: "tn", name: "Tunisia (‫تونس‬‎)", default: false },
  { dialCode: 90, iso2: "tr", name: "Turkey (Türkiye)", default: false },
  { dialCode: 993, iso2: "tm", name: "Turkmenistan", default: false },
  // { dialCode: 1, iso2: "tc", name: "Turks and Caicos Islands", default: false },
  { dialCode: 688, iso2: "tv", name: "Tuvalu", default: false },
  // { dialCode: 1, iso2: "vi", name: "U.S. Virgin Islands", default: false },
  { dialCode: 256, iso2: "ug", name: "Uganda", default: false },
  { dialCode: 380, iso2: "ua", name: "Ukraine (Україна)", default: false },
  {
    dialCode: 971,
    iso2: "ae",
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    default: false,
  },
  { dialCode: 44, iso2: "gb", name: "United Kingdom", default: false },
  { dialCode: 1, iso2: "us", name: "United States", default: false },
  { dialCode: 598, iso2: "uy", name: "Uruguay", default: false },
  {
    dialCode: 998,
    iso2: "uz",
    name: "Uzbekistan (Oʻzbekiston)",
    default: false,
  },
  { dialCode: 678, iso2: "vu", name: "Vanuatu", default: false },
  {
    dialCode: 39,
    iso2: "va",
    name: "Vatican City (Città del Vaticano)",
    default: false,
  },
  { dialCode: 58, iso2: "ve", name: "Venezuela", default: false },
  { dialCode: 84, iso2: "vn", name: "Vietnam (Việt Nam)", default: false },
  {
    dialCode: 681,
    iso2: "wf",
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    default: false,
  },
  {
    dialCode: 212,
    iso2: "eh",
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    default: false,
  },
  { dialCode: 967, iso2: "ye", name: "Yemen (‫اليمن‬‎)", default: false },
  { dialCode: 260, iso2: "zm", name: "Zambia", default: false },
  { dialCode: 263, iso2: "zw", name: "Zimbabwe", default: false },
  { dialCode: 358, iso2: "ax", name: "Åland Islands", default: false },
];
