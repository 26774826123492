<template>
  <div class="language-switcher" :style="cssVariables">
    <div v-for="(lang, i) in supportedLanguages" :key="`lang-${i}`">
      <span v-if="i !== 0" class="sw-px-1">/</span>
      <a
        :style="{ color: gmSecondaryColor }"
        class="iso"
        :class="{ active: lang === appLanguage }"
        @click="appLanguage = lang"
      >
        <span>{{ lang }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    groupAttributes() {
      return this.$store?.getters?.group?.attributes;
    },
    supportedLanguages() {
      return this.groupAttributes?.supported_languages?.split(",") || [];
    },
    appLanguage: {
      get() {
        return this.$store?.getters?.appLanguage;
      },
      set(val) {
        this.$store.commit("SET_APP_LANGUAGE", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.language-switcher {
  display: flex;

  .iso {
    text-transform: uppercase;
    font-size: 15px !important;

    &.active {
      font-weight: 700 !important;
      /*text-decoration: underline;*/
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: -4px;
        left: 0;
        background-color: var(--secondary-color);
      }
    }
  }
}

@media (max-width: 900px) {
  .language-switcher .iso.active {
    display: none;
  }
}
</style>
