const heightCalculator = ({ windowWidth, windowHeight }) => {
  // if screen is typical wider than tall (desktop, portable landscape),
  // show image in full screen height
  let height = windowHeight;
  const preferredImageProportion = 16 / 9;
  const breakpointScreenProportion = 4 / 3;
  // if screen is close to square-ish, square or tall (portable portrait),
  if (windowWidth / windowHeight < breakpointScreenProportion) {
    // then show image in preferred proportion
    height = Math.ceil(windowWidth / preferredImageProportion);
  }
  return height;
};
export default heightCalculator;
