import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "FrontPage",
    component: () => import("../views/LandingPage.vue"),
  },
  {
    path: "/messages",
    name: "MessageListPage",
    component: () => import("../views/MessageListPage.vue"),
  },
  {
    path: "/messages/:message_id",
    name: "MessagePage",
    component: () => import("../views/MessagePage.vue"),
  },
  {
    path: "/tickets/:group_plugin_id",
    name: "TicketPage",
    component: () => import("../views/TicketPage.vue"),
  },
  {
    path: "/articles/:group_plugin_id",
    name: "ArticleListPage",
    component: () => import("../views/ArticleListPage.vue"),
  },
  {
    path: "/articles/:group_plugin_id/:article_id",
    name: "ArticlePage",
    component: () => import("../views/ArticlePage.vue"),
  },
  {
    path: "/page/:article_id",
    name: "Page",
    component: () => import("../views/Page.vue"),
  },
  {
    path: "/quiz/:group_plugin_id",
    name: "QuestionPage",
    component: () => import("../views/QuizPage.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/ProfilePage.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactPage",
    component: () => import("../views/ContactPage.vue"),
  },
  {
    path: "/answers/:group_plugin_id",
    name: "QuestionaryReportPage",
    component: () => import("../views/QuestionaryReportPage.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("../views/403.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
