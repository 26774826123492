import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    windowHeight: 0,
    windowWidth: 0,
    scrollY: 0,
    user: {
      lang_pref: "",
    },
    userToken: "",
    appLanguage: "",
    group: {},
    groupAttributes: {},
    appName: "",
    navigation: {},
    navigationDrawer: false,
    isShowLoginDialog: false,
    signupQuery: null,
  },
  getters: {
    windowHeight: (state) => state.windowHeight,
    windowWidth: (state) => state.windowWidth,
    scrollY: (state) => state.scrollY,
    user: (state) => state.user,
    userToken: (state) => state.userToken,
    appLanguage: (state) => {
      const supportedLanguages =
        state?.group?.attributes?.supported_languages?.split(",") || [];
      const defaultLanguage = state?.group?.attributes?.language_iso;
      const langPref = state?.user?.lang_pref || [];

      if (supportedLanguages.includes(state?.appLanguage))
        return state?.appLanguage;

      for (const lang of langPref) {
        if (supportedLanguages.includes(lang)) return lang;
      }

      if (defaultLanguage) return defaultLanguage;

      return supportedLanguages[0];
    },
    group: (state) => state.group,
    groupAttributes: (state) => state.groupAttributes,
    appName: (state) => state.appName,
    navigation: (state) => state.navigation,
    navigationDrawer: (state) => state.navigationDrawer,
    isShowLoginDialog: (state) => state.isShowLoginDialog,
    signupQuery: (state) => state.signupQuery,
  },
  mutations: {
    SET_WINDOW_HEIGHT: (state, payload) => (state.windowHeight = payload),
    SET_WINDOW_WIDTH: (state, payload) => (state.windowWidth = payload),
    SET_SCROLL_Y: (state, payload) => (state.scrollY = payload),
    SET_USER: (state, payload) => (state.user = payload),
    SET_USER_TOKEN: (state, payload) => (state.userToken = payload),
    SET_APP_LANGUAGE: (state, payload) => (state.appLanguage = payload),
    SET_USER_LANG_PREF: (state, payload) => (state.user.lang_pref = payload),
    SET_GROUP: (state, payload) => (state.group = payload),
    SET_GROUP_ATTRIBUTES: (state, payload) => (state.groupAttributes = payload),
    SET_APP_NAME: (state, payload) => (state.appName = payload),
    SET_NAVIGATION: (state, payload) => (state.navigation = payload),
    SET_NAVIGATION_DRAWER: (state, payload) =>
      (state.navigationDrawer = payload),
    SET_IS_SHOW_LOGIN_DIALOG: (state, payload) =>
      (state.isShowLoginDialog = payload),
    SET_SIGNUP_QUERY: (state, payload) => (state.signupQuery = payload),
  },
  actions: {
    clearStore: async ({ commit }) => {
      commit("SET_USER", { lang_pref: "" });
      commit("SET_USER_TOKEN", "");
      commit("SET_SIGNUP_QUERY", null);
      commit("signup/SET_SELECTED_QUESTION", {});
      commit("signup/SET_SELECTED_QUESTION_GROUP", {});
    },
  },
  modules: {
    signup: {
      namespaced: true,
      state: {
        plugin: {},
        category: {},
        attributeTags: [],
        selectedQuestion: {},
        selectedQuestionGroup: {},
        window: 0,
      },
      getters: {
        plugin: (state) => state.plugin,
        category: (state) => state.category,
        attributeTags: (state) => state.attributeTags,
        selectedQuestion: (state) => state.selectedQuestion,
        selectedQuestionGroup: (state) => state.selectedQuestionGroup,
        window: (state) => state.window,
      },
      mutations: {
        SET_PLUGIN: (state, payload) => (state.plugin = payload),
        SET_CATEGORY: (state, payload) => (state.category = payload),
        SET_ATTRIBUTE_TAGS: (state, payload) => (state.attributeTags = payload),
        SET_SELECTED_QUESTION: (state, payload) =>
          (state.selectedQuestion = payload),
        SET_SELECTED_QUESTION_GROUP: (state, payload) =>
          (state.selectedQuestionGroup = payload),
        SET_WINDOW: (state, payload) => (state.window = payload),
      },
    },
    quiz: {
      namespaced: true,
      state: {
        window: 0,
        plugin: {},
        attribute: {},
      },
      getters: {
        window: (state) => state.window,
        plugin: (state) => state.plugin,
        attribute: (state) => state.attribute,
      },
      mutations: {
        SET_WINDOW: (state, payload) => (state.window = payload),
        SET_PLUGIN: (state, payload) => (state.plugin = payload),
        SET_ATTRIBUTE: (state, payload) => (state.attribute = payload),
      },
    },
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_STORE_KEY,
      paths: ["userToken", "appLanguage", "signupQuery"],
    }),
  ],
});
