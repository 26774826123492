import { emailRegex } from "../regex";

const emailValidation = (value) => {
  /*
   * if value contain only username of email then add domain and @ for checking the whole email validation with regex
   */
  if (!value.includes("@")) {
    value = `${value}@treffio.com`;
  } else {
    return null;
  }
  return emailRegex.test(value);
};
export default emailValidation;
