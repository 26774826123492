import Vue from "vue";

Vue.mixin({
  computed: {
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    groupPlatformFeePercentage() {
      return this.$store?.getters?.group?.platform_fee_percentage / 100;
    },
    groupPlatformFeeIncluded() {
      return this.$store?.getters?.group?.platform_fee_included;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage || "en";
    },
    gmPrimaryColor() {
      return this.$store?.getters?.group?.attributes?.lp_primary_color;
    },
    gmSecondaryColor() {
      return this.$store?.getters?.group?.attributes?.lp_secondary_color;
    },
    gmToolbarHeight() {
      const windowWidth = this.$store?.getters?.windowWidth;

      if (windowWidth <= 900) {
        return 90;
      }
      return 125;
    },
    cssVariables() {
      return {
        "--primary-color": this.gmPrimaryColor,
        "--secondary-color": this.gmSecondaryColor,
      };
    },
  },
  methods: {
    convertToPlain(html) {
      if (!html) return "";
      return html.replace(/<[^>]+>/g, "");
    },
    scrollToEl(id, offset = 0) {
      if (!id) return;

      const element = document.querySelector(id);
      const y = element?.getBoundingClientRect().top + window.scrollY - offset;

      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },
    formatPrice(amount) {
      if (!amount || !this.groupCurrency) {
        return "";
      }

      const options = {
        style: "currency",
        currency: this.groupCurrency,
      };

      let price = amount / 100;

      return new Intl.NumberFormat(this.appLanguage, options).format(price);
    },
  },
});
