import Vue from "vue";
import Vuetify from "vuetify/lib";
import en from "@/locale/en.ts";
import da from "@/locale/da.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faQuestionCircle,
  faQuestion,
  faUsers,
  faUser,
  faUserCircle,
  faIdCardAlt,
  faIdCard,
  faAddressCard,
  faIdBadge,
  faBed,
  faCouch,
  faHotel,
  faHome,
  faNewspaper,
  faFileAlt,
  faInfo,
  faInfoCircle,
  faBarcode,
  faStickyNote,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faComments,
  faComment,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faTasks,
  faListAlt,
  faBusAlt,
  faCar,
  faTaxi,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faClock,
  faDirections,
  faLocationArrow,
  faAtlas,
  faTicketAlt,
  faTshirt,
  faTag,
  faShoppingBag,
  faBriefcase,
  faCoffee,
  faAnchor,
  faAppleAlt,
  faAsterisk,
  faAtom,
  faBan,
  faBell,
  faCat,
  faCloud,
  faCode,
  faDog,
  faEye,
  faFileDownload,
  faFlask,
  faFutbol,
  faPalette,
  faPen,
  faSun,
  faTrash,
  faEnvelope,
  faSms,
  faAt,
  faGlassCheers,
  faUserTie,
  faAddressBook,
  faChartPie,
  faCog,
  faUserCog,
  faHammer,
  faPuzzlePiece,
  faWrench,
  faImage,
  faBookOpen,
  faMap,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faWineGlassAlt,
  faBullhorn,
  faBus,
  faPhoneSquare,
  faCheckCircle,
  faCheck,
  faCheckSquare,
  faRunning,
  faMapMarkerAlt,
  faPrint,
  faCircle,
  faArrowLeft,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircle as farCircle,
  faSquare as farSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faUser as farUser } from "@fortawesome/free-regular-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(
  faQuestionCircle,
  faQuestion,
  faUsers,
  faUser,
  faUserCircle,
  faIdCardAlt,
  faIdCard,
  faAddressCard,
  faIdBadge,
  faBed,
  faCouch,
  faHotel,
  faHome,
  faNewspaper,
  faFileAlt,
  faInfo,
  faInfoCircle,
  faBarcode,
  faStickyNote,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faComments,
  faComment,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faTasks,
  faListAlt,
  faBusAlt,
  faCar,
  faTaxi,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faClock,
  faDirections,
  faLocationArrow,
  faAtlas,
  faTicketAlt,
  faTshirt,
  faTag,
  faShoppingBag,
  faBriefcase,
  faCoffee,
  faAnchor,
  faAppleAlt,
  faAsterisk,
  faAtom,
  faBan,
  faBell,
  faCat,
  faCloud,
  faCode,
  faDog,
  faEye,
  faFileDownload,
  faFlask,
  faFutbol,
  faPalette,
  faPen,
  faSun,
  faTrash,
  faEnvelope,
  faSms,
  faAt,
  faGlassCheers,
  faUserTie,
  faAddressBook,
  faChartPie,
  faCog,
  faUserCog,
  faHammer,
  faPuzzlePiece,
  faWrench,
  faImage,
  faBookOpen,
  faMap,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faWineGlassAlt,
  faBullhorn,
  faBus,
  faPhoneSquare,
  faCheckCircle,
  faCheck,
  faCheckSquare,
  faRunning,
  faMapMarkerAlt,
  faPrint,
  faCircle,
  farCircle,
  farSquare,
  farUser,
  faArrowLeft,
  faRocket,
);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1f363d",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { en, da },
    current: "en",
  },
});
