import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate/dist/vee-validate.full";
import VueAppend from "vue-append";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import emailValidation from "./utils/rules/emailValidation";
import en from "vee-validate/dist/locale/en.json";
import da from "vee-validate/dist/locale/da.json";
import ru from "vee-validate/dist/locale/ru.json";
import lv from "vee-validate/dist/locale/lv.json";
import * as Rules from "vee-validate/dist/rules";
import Nl2br from "vue-nl2br";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import customErrorMessage from "@/utils/rules/messages/custom-error-messages";

for (const rule in Rules) {
  extend(rule, Rules[rule]);
}

extend("emailUser", emailValidation);

localize({ en, da, ru, lv });
localize(customErrorMessage);
Vue.component("nl2br", Nl2br);
Vue.component("overlay-scrollbars", OverlayScrollbarsComponent);

// import OverlayScrollbars from "overlayscrollbars";

// OverlayScrollbars(document.querySelectorAll("body"), {});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.request.use(
  (config) => {
    if (store?.getters?.userToken) {
      config.headers.common["Authorization"] =
        "Bearer " + store?.getters?.userToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Vue.prototype.$http = axios;

require("@/assets/styles/global-styles.scss");
require("@/mixins/globalMixin.js");
Vue.config.productionTip = false;
Vue.use(VueAppend);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
