<template>
  <div class="country-code-selector">
    <v-combobox
      ref="dialCode"
      v-model="dialCode"
      :error-messages="errors[0]"
      :filter="filter"
      :items="dialCodes"
      item-value="dialCode"
      :search-input.sync="searchInput"
      :placeholder="$vuetify.lang.t('$vuetify.phoneCode')"
      auto-select-first
      attach
      flat
      :solo="!minimal"
      @keydown="onKeyDown"
      append-icon="mdi-magnify"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Nothing found</span>
        </v-list-item>
      </template>
      <template slot="selection" slot-scope="data">
        <div :style="{ fontSize: '14px' }">
          <span
            v-if="data.item.iso2"
            :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
          ></span>
          <span v-if="data.item.dialCode" class="font-weight-medium"
            >+{{ data.item.dialCode }}</span
          >
        </div>
      </template>
      <template slot="item" slot-scope="data">
        <div :style="{ fontSize: '14px' }" class="slot-item">
          <span
            v-if="data.item.iso2"
            :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
          ></span>
          <span v-if="data.item.dialCode" class="font-weight-medium"
            >+{{ data.item.dialCode }}</span
          >
          <span v-if="data.item.name" class="ml-2">{{ data.item.name }}</span>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { countries } from "@/countries.js";
import "flag-icon-css/css/flag-icon.css";

export default {
  props: ["value", "errors", "minimal"],
  data: () => ({
    searchInput: "",
  }),
  computed: {
    dialCode: {
      get() {
        return this.transformedCountryCodes.find(
          (el) => el.dialCode === this.value,
        );
      },
      set(val) {
        if (!val) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val.dialCode);
        }
      },
    },
    sortedCountryCodes() {
      return [
        ...countries.filter((el) => el.default),
        ...countries.filter((el) => !el.default),
      ];
    },
    transformedCountryCodes() {
      return this.sortedCountryCodes.map((el) => ({
        dialCode: el.dialCode.toString(),
        iso2: el.iso2,
        name: el.name,
        plainText: `+${el.dialCode} ${el.name}`,
      }));
    },
    dialCodes() {
      return [
        { header: this.$vuetify.lang.t("$vuetify.dialCodeHeader") },
        ...this.transformedCountryCodes,
      ];
    },
  },
  watch: {
    dialCode(val) {
      if (val) {
        this.$nextTick(() => this.$refs.dialCode.blur());
      }
    },
  },
  methods: {
    filter(item, queryText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const query = hasValue(queryText);

      return (
        item.plainText
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    onKeyDown(e) {
      if (!this.dialCode && e.code === "Enter") {
        return;
      }

      this.dialCode = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
