<template>
  <div class="app-confirmation-modal">
    <v-dialog v-model="dialog" width="560" persistent>
      <v-card class="rounded-lg">
        <div class="sw-pt-6 sw-px-7">
          <div
            :style="{ color: gmPrimaryColor }"
            class="sw-block-title text-center"
          >
            {{ title }}
          </div>
        </div>
        <div :style="cssVariables">
          <overlay-scrollbars
            :options="scrollbarOptions"
            :style="{ maxHeight: '260px' }"
          >
            <div v-html="content" class="sw-px-7"></div>
          </overlay-scrollbars>
        </div>
        <div class="sw-py-6 sw-px-7 text-center">
          <v-btn
            rounded
            depressed
            large
            class="mx-1 primary--text white text-none"
            :loading="isLoading"
            @click="cancel"
            >{{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn
          >
          <v-btn
            rounded
            depressed
            large
            :style="{ backgroundColor: gmSecondaryColor }"
            class="mx-1 text-none white--text"
            :loading="isLoading"
            @click="confirm"
            >{{ $vuetify.lang.t("$vuetify.accept") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    resolve: null,
    scrollbarOptions: {
      scrollbars: {
        visibility: "auto",
        autoHide: "never",
        autoHideDelay: 1300,
      },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
    plugin: {},
  }),
  methods: {
    async open() {
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    confirm() {
      this.dialog = false;
      this.resolve(true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
