var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-toolbar",class:{
    'fill-background': !_vm.isTransparent,
    scrolled: _vm.isScrolled,
    subpage: _vm.isSubpage,
  }},[_c('v-row',{staticClass:"align-center",style:({ height: `${_vm.gmToolbarHeight}px` }),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[(_vm.showBackButton)?_c('a',{style:({ color: _vm.gmSecondaryColor }),on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"25px"},attrs:{"icon":['fas', 'arrow-left']}})],1):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-overflow-ellipsis text-center"},[(_vm.showGroupName)?[(_vm.isScrolled || _vm.isSubpage)?_c('span',{staticClass:"font-weight-medium cursor-pointer event-name",style:({ color: _vm.gmPrimaryColor }),on:{"click":_vm.routeToHome}},[_vm._v(" "+_vm._s(_vm.groupName)+" ")]):_vm._e()]:_vm._e()],2)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"align-center no-gutters flex-nowrap justify-end"},[(_vm.showLanguages)?_c('AppLanguageSwitcher'):_vm._e(),_c('AppLogin'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showNavigationDrawer)?_c('a',{staticClass:"sw-ml-5 navigation-toggler",on:{"click":function($event){$event.stopPropagation();_vm.navigationDrawer = !_vm.navigationDrawer}}},[_c('v-icon',{staticStyle:{"font-size":"35px"},style:({ color: _vm.gmSecondaryColor })},[_vm._v("mdi-menu ")]),_c('span',{staticClass:"toggler-caption text-center",style:({
                color: _vm.gmSecondaryColor,
                display: 'block',
              })},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.menu")))])],1):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }