<template>
  <div class="profile">
    <a
      v-if="user && user.id"
      @click.stop="routeToProfile"
      class="sw-ml-5 button"
    >
      <font-awesome-icon
        :icon="['fa', 'user']"
        :style="{ color: gmSecondaryColor }"
        style="font-size: 24px"
      />
    </a>
    <a v-else @click.stop="openDialog" class="sw-ml-5 button">
      <font-awesome-icon
        :icon="['fa', 'user']"
        :style="{ color: gmSecondaryColor }"
        style="font-size: 24px"
      />
    </a>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card :disabled="isLoading" class="text-center mx-auto rounded-lg">
        <div class="pa-1 text-right">
          <v-btn icon @click="dialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-window v-model="window" class="px-sm-12 mx-sm-12">
          <v-window-item :value="0">
            <v-card-text>
              <div class="sw-block-title" :style="{ color: gmPrimaryColor }">
                {{ $vuetify.lang.t("$vuetify.smsVerification") }}
              </div>
              <ValidationObserver
                ref="smsObserver"
                @keyup.enter.native="smsVerification"
                v-slot="{ errors }"
              >
                <v-row class="no-gutters">
                  <div :style="{ width: '120px' }">
                    <ValidationProvider
                      vid="phone_code"
                      :name="$vuetify.lang.t('$vuetify.phoneCode')"
                      rules="required|numeric|max:3"
                      mode="passive"
                      tag="div"
                    >
                      <CountryCodeSelector
                        v-model="dialCode"
                        :errors="errors"
                        :minimal="true"
                        class="custom-select-login"
                      />
                    </ValidationProvider>
                  </div>
                  <v-col class="pl-2">
                    <ValidationProvider
                      vid="phone"
                      :name="$vuetify.lang.t('$vuetify.phone')"
                      :rules="phoneNumberRules"
                      mode="passive"
                    >
                      <v-text-field
                        v-model="phone"
                        placeholder="12345678"
                        single-line
                        hide-details
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="mb-8 pt-1">
                    <div
                      v-for="(error, i) in listOfErrors(errors)"
                      :key="`error-${i}`"
                    >
                      <span class="sw-small error--text">{{ error }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      large
                      rounded
                      depressed
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="text-none white--text"
                      @click="smsVerification"
                    >
                      <span class="sw-px-2">
                        {{ $vuetify.lang.t("$vuetify.send") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="1">
            <v-card-text>
              <div class="sw-block-title" :style="{ color: gmPrimaryColor }">
                {{ $vuetify.lang.t("$vuetify.emailVerification") }}
              </div>
              <ValidationObserver
                ref="emailObserver"
                @keyup.enter.native="emailVerification"
                v-slot="{ errors }"
              >
                <v-row class="no-gutters">
                  <v-col cols="12">
                    <ValidationProvider
                      vid="email"
                      :name="$vuetify.lang.t('$vuetify.email')"
                      rules="required|email"
                      mode="passive"
                    >
                      <v-text-field
                        v-model="email"
                        label="Email"
                        placeholder="example@domain.com"
                        single-line
                        hide-details
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="mb-8 pt-1">
                    <div
                      v-for="(error, i) in listOfErrors(errors)"
                      :key="`error-${i}`"
                    >
                      <span class="sw-small error--text">{{ error }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      large
                      rounded
                      depressed
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="text-none white--text"
                      @click="emailVerification"
                      :loading="isLoading"
                    >
                      <span class="sw-px-2">
                        {{ $vuetify.lang.t("$vuetify.send") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-text v-show="email || phone">
              <div class="sw-block-title" :style="{ color: gmPrimaryColor }">
                {{ $vuetify.lang.t("$vuetify.enterPin") }}
              </div>
              <ValidationObserver
                ref="pinObserver"
                @keyup.enter.native="login"
                v-slot="{ errors }"
              >
                <v-row class="no-gutters">
                  <v-col cols="12">
                    <ValidationProvider
                      vid="pin"
                      :name="$vuetify.lang.t('$vuetify.pin')"
                      rules="required"
                      mode="passive"
                    >
                      <v-text-field
                        v-model="pin"
                        label="Pin"
                        placeholder="0000"
                        single-line
                        hide-details
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="mb-8 pt-1">
                    <div
                      v-for="(error, i) in listOfErrors(errors)"
                      :key="`error-${i}`"
                    >
                      <span class="sw-small error--text">{{ error }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      large
                      rounded
                      depressed
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="text-none white--text"
                      @click="login"
                      :loading="isLoading"
                    >
                      <span class="sw-px-2">
                        {{ $vuetify.lang.t("$vuetify.logIn") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
            <v-card-text v-show="!email && !phone">
              <div class="sw-block-title" :style="{ color: gmPrimaryColor }">
                {{ $vuetify.lang.t("$vuetify.getVerificationLinkText") }}
              </div>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-card-text class="pa-4">
          <v-spacer></v-spacer>
          <v-item-group v-model="window" class="text-center" mandatory>
            <v-item
              v-for="n in 3"
              :key="`btn-${n}`"
              v-slot:default="{ active, toggle }"
            >
              <span :input-value="active" class="mx-1 sw-small" @click="toggle">
                <font-awesome-icon
                  :icon="active ? ['fa', 'circle'] : ['far', 'circle']"
                  :color="gmPrimaryColor"
                />
              </span>
            </v-item>
          </v-item-group>
          <v-spacer></v-spacer>
        </v-card-text>
        <div class="pt-sm-9">
          <v-card-text v-if="window === 0">
            <a @click.stop="window = 1">
              <span class="sw-small grey--text text--darken-1">
                {{ $vuetify.lang.t("$vuetify.emailVerificationLinkText") }}
              </span>
            </a>
          </v-card-text>
          <v-card-text v-if="window === 1">
            <a @click.stop="window = 0">
              <span class="sw-small grey--text text--darken-1">
                {{ $vuetify.lang.t("$vuetify.smsVerificationLinkText") }}
              </span>
            </a>
          </v-card-text>
          <v-card-text v-if="window === 2">
            <a @click.stop="window = 0">
              <span class="sw-small grey--text text--darken-1">
                {{ $vuetify.lang.t("$vuetify.back") }}
              </span>
            </a>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <AppConfirmationModal
      ref="appConfirmationModal"
      :title="$vuetify.lang.t('$vuetify.termsAndConditions')"
      :content="signupPrivacyDisclaimer"
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import CountryCodeSelector from "@/components/CustomFields/CountryCodeSelector.vue";
import AppConfirmationModal from "@/components/AppConfirmationModal.vue";

export default {
  data: () => ({
    isLoading: false,
    window: 0,
    verification: {},
    email: null,
    dialCode: null,
    phone: null,
    pin: "",
    signupPlugin: {},
  }),
  computed: {
    phoneNumberRules() {
      const rules =
        process?.env?.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";

      return `required|${rules}`;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    user() {
      return this.$store?.getters?.user;
    },
    isUserSubscribed() {
      return this.user?.groups?.includes(this.groupId);
    },
    dialog: {
      set(val) {
        this.$store.commit("SET_IS_SHOW_LOGIN_DIALOG", val);
      },
      get() {
        return this.$store.getters.isShowLoginDialog;
      },
    },
    signupRequiredPrivacyDisclaimer() {
      return this.signupPlugin?.attributes?.signup_required_privacy_disclaimer;
    },
    signupPrivacyDisclaimer() {
      return this.signupPlugin?.attributes?.signup_privacy_disclaimer;
    },
  },
  components: {
    CountryCodeSelector,
    AppConfirmationModal,
  },
  methods: {
    listOfErrors(errors) {
      const errorValues = Object.values(errors);

      return errorValues.map((e) => e[0]).filter((v) => v);
    },
    clearUserInput() {
      this.dialog = false;
      this.window = 0;
      this.verification = {};
      this.email = null;
      this.dialCode = null;
      this.phone = null;
      this.pin = "";
    },
    async smsVerification() {
      this.verification = {};

      const valid = await this.$refs.smsObserver.validate();

      if (!valid) return;

      this.pin = null;

      if (this.$refs.pinObserver) {
        this.$refs.pinObserver.reset();
      }

      const params = {
        phone_code: this.dialCode,
        phone: this.phone,
      };

      this.isLoading = true;

      this.$http.get(`/auth/reminder`, { params }).then(
        (response) => {
          this.isLoading = false;

          if (response?.data?.status !== "ok") return;

          this.verification = {
            phone_code: this.dialCode,
            phone: this.phone,
          };

          this.window = 2;
        },
        (error) => {
          this.isLoading = false;

          if (error?.response?.status === 422) {
            this.$refs.smsObserver.setErrors({
              phone: [this.$vuetify.lang.t("$vuetify.userNotFound")],
            });
          }
        },
      );
    },
    async emailVerification() {
      this.verification = {};

      const valid = await this.$refs.emailObserver.validate();

      if (!valid) return;

      this.pin = null;

      if (this.$refs.pinObserver) {
        this.$refs.pinObserver.reset();
      }

      const params = {
        email: this.email,
      };

      this.isLoading = true;

      this.$http.get(`/auth/reminder`, { params }).then(
        (response) => {
          this.isLoading = false;

          if (response?.data?.status !== "ok") return;

          this.verification = {
            email: this.email,
          };

          this.window = 2;
        },
        (error) => {
          this.isLoading = false;

          if (error?.response?.status === 422) {
            this.$refs.emailObserver.setErrors({
              email: [this.$vuetify.lang.t("$vuetify.userNotFound")],
            });
          }
        },
      );
    },
    async subscribe() {
      await this.$http.get(`/auth/subscribe`, {
        params: { groups: this.groupId },
      });

      this.user?.groups?.push(this.groupId);
    },
    async login() {
      try {
        const isValid = await this.$refs.pinObserver.validate();

        if (!isValid) return;

        this.isLoading = true;

        const signupPluginParams = {
          prefix: "signup",
          with_attributes: 1,
          per_page: 100,
        };

        const signupPluginResponse = await this.$http.get(
          `/groups/${this.groupId}/plugins`,
          {
            params: signupPluginParams,
          },
        );

        this.signupPlugin = signupPluginResponse?.data?.data[0];

        const loginParams = {
          email: this.verification.email,
          phone_code: this.verification.phone_code,
          phone: this.verification.phone,
          pin: this.pin,
        };

        const loginResponse = await this.$http.get(`/auth/login`, {
          params: loginParams,
        });

        if (!loginResponse?.data?.data?.is_latest_terms_accepted) {
          if (this.signupRequiredPrivacyDisclaimer) {
            const isConfirmed = await this.$refs?.appConfirmationModal?.open();

            if (!isConfirmed) {
              this.clearUserInput();

              this.isLoading = false;

              return;
            }
          }

          await axios({
            url: "/auth/quick_update",
            method: "post",
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${loginResponse?.data?.data?.token}`,
            },
            params: {
              terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            },
          });
        }

        const user = loginResponse?.data?.data || { lang_pref: "" };
        const token = loginResponse?.data?.data?.token || "";

        this.$store.commit("SET_USER", user);
        this.$store?.commit("SET_USER_TOKEN", token);

        if (!this.isUserSubscribed) {
          await this.subscribe();
        }

        this.isLoading = false;

        this.$router.push({ name: "FrontPage" }).catch(() => {});

        this.clearUserInput();
      } catch (error) {
        if (error) {
          this.isLoading = false;

          if (error?.response?.status === 422) {
            this.$refs.pinObserver.setErrors({
              pin: ["Pin not found"],
            });
          }
        }
      }
    },
    openDialog() {
      if (this.$refs.smsObserver) {
        this.$refs.smsObserver.reset();
      }
      if (this.$refs.emailObserver) {
        this.$refs.emailObserver.reset();
      }
      if (this.$refs.pinObserver) {
        this.$refs.pinObserver.reset();
      }
      this.dialog = true;
    },
    routeToProfile() {
      this.$router.push({ name: "Profile" }).catch(() => {
        this.navigationDrawer = false;
      });
    },
  },
};
</script>

<style lang="scss">
.custom-select-login {
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 5px !important;
  }

  .v-autocomplete__content {
    min-width: 306px !important;
    max-height: 248px !important;
  }
}
</style>

<style scoped lang="scss">
.profile {
  .button,
  svg {
    display: block;
  }
}

@media (max-width: 900px) {
  .button {
    margin-left: 15px !important;
  }
}
</style>
